
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Icon } from "@rsuite/icons"
import { View ,Text} from "react-native"
import { IconButton } from "rsuite"
import CustomText from "../../../Components/CustomText"





const CustomListItem = ({item,openFileDrawer,type}) => {

    function parseData(data){
        if(data != null && data != undefined){
            if(item.type != null && item.type != undefined && item.type == "DATE"){
                console.log("reverse date")
                return reverseDate(data);
            }
            if(item.header.includes("TUTAR")){
                return data.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
            }
            else if(item.header.includes("KM")){
                return data.toLocaleString('tr-TR', { style: 'unit', unit: 'kilometer', unitDisplay: 'short' });
            }
            else if(item.header.includes("TARİH")){
                return reverseDate(data);
                console.log("reverse date")
            }
            else{
                return data;
            }
        }
        else{
            return "Bilgi Yok";
        }
    }

    function reverseDate(date){
        if(!date.includes(":")){
        if(date != null && date != undefined){
            let dateArray = date.split("-");
            return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
        }
        else{
            return "Bilgi Yok";
        }}
        else {
            let temp = new Date(date);
            console.log(temp)
            console.log(temp.getDate())
            return temp.getDate() + "/" + (temp.getMonth() + 1) + "/" + temp.getFullYear();
        }
    }


    function copyToClipboard(text) {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      }


    return(
        <View style = {{flex: 1}}>
            {type != null && type != undefined && type == "FILE" ?
                (
                <View style = {{flexDirection: 'row', justifyContent:'flex-start',alignItems:'center'}}>
                    <View style= {{marginHorizontal: 10}}>
                       <CustomText style={{textAlign: 'center', fontWeight: 'bold'}}>{item.header}</CustomText>
                    </View>
                   <IconButton onClick={()=> {openFileDrawer(item.type); console.log("open file drawer ")}} icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}></IconButton>
                </View>
                )
                    :
                (
                <View style = {{flexDirection: 'row', justifyContent:'flex-start' ,alignItems:'center', maxWidth: 500}}>
                    <View style= {{marginHorizontal: 10}}>
                    <Icon><FontAwesomeIcon icon = {item.icon}/></Icon>
                   </View>
                    <View style= {{marginHorizontal: 10, flex: 1}}>
                       <CustomText fontSize= {14}>{item.header}</CustomText>
                    </View>
                    <View style= {{marginHorizontal: 10, flex: 2}}>
                       <CustomText fontWeight = {800} fontSize= {14} > {parseData(item.data)} </CustomText>
                   </View>
                   {/* <IconButton onClick={()=> {copyToClipboard(item.data)}} icon={<Icon><FontAwesomeIcon icon={faCopy} /></Icon>}></IconButton> */}
                </View>
                )
            }
      </View>
    )
  }

  export default CustomListItem;
