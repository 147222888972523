import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
    Panel,
    Button,
    ButtonGroup,
    Table,
    Grid,
    Row,
    DateRangePicker,
    Pagination,
    Input, Stack
} from 'rsuite';
import axios from 'axios';
import moment from 'moment';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell
} from 'recharts';
import configdata from "../../config/config.json";
import { useUserToken } from "../../hooks/useUserToken";
import CustomText from "../../Components/CustomText";
import 'moment/locale/tr';
import * as XLSX from 'xlsx';

moment.locale('tr');

// Format number as Turkish Lira
function formatTL(amount) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

// Format kilometers (if needed)
function formatKM(amount) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(amount);
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Excel export helper
const exportToExcel = (data, filename = 'SellBuyData.xlsx') => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SellBuy Data');
    XLSX.writeFile(workbook, filename);
};

// PLAKA'ya göre gruplandırma fonksiyonu
const groupByPlaka = (data) => {
    const grouped = data.reduce((acc, item) => {
        const plate = item.PLAKA;
        if (!acc[plate]) {
            acc[plate] = 0;
        }
        acc[plate] += Number(item.FINAL_PRICE) || 0;
        return acc;
    }, {});
    return Object.keys(grouped).map(plate => ({
        plaka: plate,
        total: grouped[plate]
    }));
};

const SellBuyScreen = () => {
    const [sellBuyData, setSellBuyData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [timeFilter, setTimeFilter] = useState('yearly'); // Default filter period
    const [loading, setLoading] = useState(true);
    const [dailyData, setDailyData] = useState([]);
    const [plakaData, setPlakaData] = useState([]);

    const { fetchIdToken } = useUserToken();

    // Fetch data when component mounts
    useEffect(() => {
        fetchSellBuyData();
    }, []);

    // Whenever filteredData changes, group by day for the daily chart
    useEffect(() => {
        const aggregatedDailyData = groupByDay(filteredData);
        setDailyData(aggregatedDailyData);

        // Grup verilerini plakaya göre de oluşturun
        const aggregatedPlakaData = groupByPlaka(filteredData);
        setPlakaData(aggregatedPlakaData);
    }, [filteredData]);

    // Fetch the merged sell/buy data from your API
    const fetchSellBuyData = async () => {
        setLoading(true);
        const idToken = await fetchIdToken();
        const headers = { Authorization: `Bearer ${idToken}` };

        const api = configdata.API_BASE + '/cardata';
        const body = {
            type: "GET_ALL_SELL_INFO",
            ENV: configdata.APP_ENV
        };

        try {
            const response = await axios.post(api, body, { headers });
            console.log('Sell/Buy data:', response.data);
            // Transform data: add a computed "date" field to use for filtering and charts.
            const transformedData = response.data.map(item => {
                // Use SELL_DATE if available, otherwise use BUY_DATE.
                return {
                    ...item,
                    date: item.SELL_DATE ? item.SELL_DATE : item.BUY_DATE
                };
            });
            setSellBuyData(transformedData);
            applyFilter(transformedData, 'yearly');
        } catch (error) {
            console.error('Error fetching sell/buy data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Filter data based on the computed "date" field
    const applyFilter = (data, period, start, end) => {
        let filtered;
        switch (period) {
            case 'weekly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('week'), moment().endOf('week'))
                );
                break;
            case 'monthly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('month'), moment().endOf('month'))
                );
                break;
            case 'yearly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('year'), moment().endOf('year'))
                );
                break;
            case 'custom':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment(start), moment(end))
                );
                break;
            default:
                filtered = data;
        }
        setFilteredData(filtered);
        setTimeFilter(period);
    };

    // Optionally filter by a keyword (e.g. search in NOTES)
    const applyKeywordFilter = (data, keyword, field) => {
        if (!keyword) return data;
        return data.filter(item => item[field].toLowerCase().includes(keyword.toLowerCase()));

    }
    // Group the data by day (using computed "date")
    const groupByDay = (data) => {
        const grouped = data.reduce((acc, item) => {
            const dateKey = moment(item.date).format('YYYY-MM-DD');
            if (!acc[dateKey]) acc[dateKey] = 0;
            // Sum the "FINAL_PRICE" (Satış Tutarı Toplam)
            acc[dateKey] += Number(item.FINAL_PRICE) || 0;
            return acc;
        }, {});

        return Object.keys(grouped).map(date => ({
            date,
            total: grouped[date],
        }));
    };

    return (
        <View style={{ padding: 20 }}>
            <Panel header="Satış & Alım Raporu" shaded style={styles.panel}>
                <ButtonGroup>
                    <Button
                        appearance={timeFilter === 'weekly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(sellBuyData, 'weekly')}
                    >
                        Bu Hafta
                    </Button>
                    <Button
                        appearance={timeFilter === 'monthly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(sellBuyData, 'monthly')}
                    >
                        Ay {moment().format('MMMM')}
                    </Button>
                    <Button
                        appearance={timeFilter === 'yearly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(sellBuyData, 'yearly')}
                    >
                        Yıl {moment().year()}
                    </Button>
                    <DateRangePicker
                        style={{ marginLeft: 10 }}
                        placeholder="Tarih Aralığı Seç"
                        onChange={value => {
                            if (value) {
                                applyFilter(sellBuyData, 'custom', value[0], value[1]);
                            }
                        }}
                    />
                    <Button
                        style={{ marginLeft: 10 }}
                        appearance="primary"
                        onClick={() => exportToExcel(filteredData, 'SellBuyData.xlsx')}
                    >
                        Export to Excel
                    </Button>
                </ButtonGroup>

                <Grid fluid style={{ marginTop: 20 }}>
                    <CustomText fontSize={17}>
                        Toplam Satış Tutarı Toplam: {formatTL(filteredData.reduce((acc, item) => acc + (Number(item.FINAL_PRICE) || 0), 0))}
                    </CustomText>

                    {/* Günlük Tablo */}
                    <Row>
                        <CustomText fontSize={15}>Günlük Tablo</CustomText>
                        <ResponsiveContainer height={300} width="100%">
                            <BarChart data={dailyData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <div style={{
                                                    background: 'white',
                                                    border: '1px solid #ccc',
                                                    padding: '10px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <p><strong>Tarih:</strong> {label}</p>
                                                    <p><strong>Tutar:</strong> {formatTL(payload[0].value)}</p>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <Bar dataKey="total" fill="#82ca9d">
                                    {dailyData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Row>

                    {/* PLAKA'ya Göre Tablo */}
                    <Row style={{ marginTop: 40 }}>
                        <CustomText fontSize={15}>Plakaya Göre Satış Tutarları</CustomText>
                        <ResponsiveContainer height={300} width="100%">
                            <BarChart data={plakaData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                                <XAxis dataKey="plaka" />
                                <YAxis />
                                <Tooltip
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <div style={{
                                                    background: 'white',
                                                    border: '1px solid #ccc',
                                                    padding: '10px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <p><strong>Plaka:</strong> {label}</p>
                                                    <p><strong>Tutar:</strong> {formatTL(payload[0].value)}</p>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <Bar dataKey="total" fill="#82ca9d">
                                    {plakaData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Row>
                </Grid>
                <Stack style={{ marginTop: 20   , flexDirection: 'row', justifyContent: 'space-around'}}>
                <Input
                    style={{ width: 200, marginTop: 10 }}
                    placeholder="PLAKA Ara"
                    onChange={value => setFilteredData(applyKeywordFilter(sellBuyData, value, 'PLAKA'))}
                />
                <Input
                    style={{ width: 200, marginTop: 10 }}
                    placeholder="Müşteri Ara"
                    onChange={value => setFilteredData(applyKeywordFilter(sellBuyData, value, 'SELL_CLIENT'))}
                />
                </Stack>
                <Button onClick={fetchSellBuyData} loading={loading} style={{ marginTop: 10 }}>
                    FETCH
                </Button>
                <SellBuyTable data={filteredData} />
            </Panel>
        </View>
    );
};

const styles = {
    panel: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
};

// Define the page size for table pagination
const PAGE_SIZE = 20;

// SellBuyTable component (değişiklik yapmadan mevcut haliyle)
const SellBuyTable = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortType, setSortType] = useState(null);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Sort the data based on the selected column
    const sortedData = React.useMemo(() => {
        if (!sortColumn || !sortType) return data;
        return [...data].sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (x == null) x = '';
            if (y == null) y = '';
            if (sortColumn === 'date') {
                x = moment(x);
                y = moment(y);
                return sortType === 'asc' ? x - y : y - x;
            }
            if (typeof x === 'string' && typeof y === 'string') {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }
            return sortType === 'asc'
                ? x > y ? 1 : x < y ? -1 : 0
                : x < y ? 1 : x > y ? -1 : 0;
        });
    }, [data, sortColumn, sortType]);

    // Paginate the sorted data
    const paginatedData = sortedData.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    );

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
    };

    return (
        <div>
            <Table
                autoHeight
                data={paginatedData}
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
            >
                <Table.Column width={200} align="left" sortable>
                    <Table.HeaderCell>Plaka</Table.HeaderCell>
                    <Table.Cell dataKey="PLAKA" />
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Alış Tarihi</Table.HeaderCell>
                    <Table.Cell dataKey="BUY_DATE">
                        {(rowData) => moment(rowData.BUY_DATE).format('YYYY-MM-DD')}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Satış Tarihi</Table.HeaderCell>
                    <Table.Cell dataKey="SELL_DATE">
                        {(rowData) => moment(rowData.SELL_DATE).format('YYYY-MM-DD')}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Alış - Satış Gün Farkı</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const buyDate = moment(rowData.BUY_DATE);
                            const sellDate = moment(rowData.SELL_DATE);
                            return sellDate.diff(buyDate, 'days');
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Alış Tutarı</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const amount = rowData.BUY_DATE ? rowData.BUY_TAX_INC_PRICE : 0;
                            return formatTL(amount || 0);
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Satış Tutarı Toplam</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const amount = rowData.FINAL_PRICE;
                            return formatTL(amount || 0);
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Satış Tutarı Faturalı</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const amount = rowData.TAX_INC_PRICE;
                            return formatTL(amount || 0);
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>Faturasız Tutar</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const amount = rowData.FINAL_PRICE - rowData.TAX_INC_PRICE;
                            return formatTL(amount || 0);
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>KDV Oranı</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => rowData.KDV + "%"}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="left" sortable>
                    <Table.HeaderCell>KDV Tutarı</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            const amount = rowData.TAX_INC_PRICE * rowData.KDV_RATE / 100;
                            return formatTL(amount || 0);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1} align="left">
                    <Table.HeaderCell>Alıcı</Table.HeaderCell>
                    <Table.Cell dataKey="SELL_CLIENT" />
                </Table.Column>
            </Table>
            <CustomText style={{ marginTop: 10 }}> Toplam: {data.length} kayıt</CustomText>

            <Pagination
                style={{ marginTop: 10, textAlign: 'center' }}
                total={data.length}
                limit={PAGE_SIZE}
                activePage={currentPage}
                onChangePage={handlePageChange}
            />
        </div>
    );
};

export default SellBuyScreen;
